<template>
    <page-layout-nolo :loading="isLoading" :title="$t('previousOrders')" :back="$t('btn.backToYourAccount')">
        <v-container>
            <v-card class="mb-10 elevation-8 pa-4">
                <order-list :orders="orders" @order-select="viewOrder"/>
                <v-row>
                    <v-col cols="12" sm="5">
                        <v-btn block outlined color="secondary" @click="backToAccount">{{ $t('btn.backToYourAccount')}}</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </page-layout-nolo>
</template>

<script>

    import APICustomer from "../../api/APICustomer";
    import OrderList from "../../components/Account/OrderList";

    export default {
        name: "YourPreviousOrders",
        components: {OrderList},
        data() {
            return {
                isLoading: true,
                orders: []
            }
        },
        mounted() {
            const me = this;
            APICustomer.getPreviousOrders(this)
                .then((response) => {
                    me.orders = response.data.data
                })
                .finally(() => {
                    me.isLoading = false;
                })
        },
        methods: {
            backToAccount() {
                this.$router.replace({ name: 'account' });
            },
            viewOrder(payload) {
                this.$router.push('/account/previous-order/' + payload.id);
            }
        }
    }
</script>

<style scoped>

</style>
